.chats{
    position: relative;
    height: 400px;
    width: 250px;
}

.chats__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: skyblue;
    height: 40px;
}

.chats__search {
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 8px;
    padding-right: 8px;
}

.chats__avatar {
    height: 25px !important;
    width: 25px !important;
}

.chats__posts {
    background-color: white;
    height: 359px;
    overflow: scroll;
}

.chats__posts::-webkit-scrollbar{
    display: none
}

.white{
    color: white !important;
}