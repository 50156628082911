.chatview{
    position: relative;  
}

.chatview > img {
    cursor: pointer;
}

.chatview__timer {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
}