

.login {
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100%;
}

.login__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}