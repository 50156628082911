.webcamCapture {
    position:  relative;
}

.webcamCapture__button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: red;
}