.chat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px whitesmoke;
    cursor: pointer;
}

.chat__avatar {
    height: 35px !important;
    width: 35px !important;

}

.read__icon {
    color: red;
}

.chat__info {
    padding-left: 5px;
    flex: 1;
}

.chat__info > h4 {
    font-size: 11px;
    font-weight: 500;
}

.chat__info > p {
    font-size: 8px;
}

.chats__takePicIcon {
    position: absolute;
    background-color: white;
    border-radius: 200px;
    font-size: 40px im !important;
    cursor: pointer;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.chats__takePicIcon:hover{
    opacity: 0.7;
}